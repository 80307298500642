import { createApi } from "@reduxjs/toolkit/query/react";
import { baseAppQuery } from "./baseQuery";
import { AdditionalContractData } from "types/contracts";
import { StatusHistory } from "types/statuses";
import { Dictionary } from "types/dictionary";
import { Filter } from "types/filters";

const ENDPOINT = "additional-contract";
const additionalContractApi:any = createApi({
    reducerPath: "additionalContractApi",
    baseQuery: baseAppQuery,
    refetchOnMountOrArgChange: true,
    tagTypes: ["additionalContract"],
    endpoints: (builder)=>({
        getAdditionalContractById: builder.query<AdditionalContractData, string>({
            query: (id)=> `${ENDPOINT}/${id}`
        }),
        getAdditionalContractListById: builder.query<AdditionalContractData, {id:string, dataType:"application"|"contract"|"organization", filter: Filter}>({
            query: ({id, dataType, filter})=> {
                const queryParams = new URLSearchParams();
                queryParams.set("page", filter.pageNumber.toString());
                queryParams.set("size", filter.pageSize.toString());
                const idType = dataType==="organization"?"org": dataType;
                return `${ENDPOINT}/fetch-all-by-${dataType}?${idType}Id=${id}&${queryParams}`
            },
            providesTags: ["additionalContract"]
        }),
        getAdditionalContractListByOrganizationContract: builder.query<AdditionalContractData, {contractId:string, orgId:string}>({
            query: ({contractId, orgId})=> `${ENDPOINT}/fetch-all-by-organization-contract?contractId=${contractId}&orgId=${orgId}`
        }),
        getAdditionalContractHistoryById: builder.query<StatusHistory, string>({
            query: (id)=> `${ENDPOINT}/status-history/${id}`
        }),
        getAdditionalContractTypes: builder.query<Dictionary, Dictionary>({
            query: ()=>`dictionary/${ENDPOINT}-type/list`,
        }),
        createAdditionalContract: builder.mutation<AdditionalContractData, AdditionalContractData>({
            query: (data)=>({
                url:ENDPOINT,
                method:"POST",
                body:data
            }),
            invalidatesTags: ["additionalContract"]
        }),
        updateAdditionalContract:builder.mutation<AdditionalContractData, AdditionalContractData>({
            query:(data)=>({
                url:ENDPOINT,
                method:"PUT",
                body:data,
            }),
            invalidatesTags: ["additionalContract"]
        })
    })

});
export const{
    useGetAdditionalContractByIdQuery,
    useGetAdditionalContractListByIdQuery,
    useGetAdditionalContractByOrganizationContractQuery,
    useGetAdditionalContractHistoryByIdQuery,
    useGetAdditionalContractTypesQuery,
    useCreateAdditionalContractMutation,
    useUpdateAdditionalContractMutation
} = additionalContractApi;
export default additionalContractApi;