// third-party
import { combineReducers } from "redux";


// project imports
import snackbarReducer from "./slices/snackbar";
import authApi from "services/auth";
import clientsApi from "services/clients";
import employeesApi from "services/employees";
import debtorsApi from "services/debtors";
import applicationApi from "services/applications";
import dictionaryApi from "services/dictionary";
import signatureApi from "services/signatures";
import contractsApi from "services/contracts";
import enclosuresApi from "services/enclosures";
import attachmentsApi from "services/attachments";
import disbursementApi from "services/disbursements";
import reportApi from "services/report";
import organizationsApi from "services/organizations";
import leadsApi from "services/leads";
import additionalContractApi from "services/additionalContracts";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  snackbar: snackbarReducer,
  [authApi.reducerPath]: authApi.reducer,
  [clientsApi.reducerPath]: clientsApi.reducer,
  [employeesApi.reducerPath]: employeesApi.reducer,
  [debtorsApi.reducerPath]: debtorsApi.reducer,
  [applicationApi.reducerPath]: applicationApi.reducer,
  [dictionaryApi.reducerPath]: dictionaryApi.reducer,
  [signatureApi.reducerPath]: signatureApi.reducer,
  [contractsApi.reducerPath]: contractsApi.reducer,
  [enclosuresApi.reducerPath]: enclosuresApi.reducer,
  [attachmentsApi.reducerPath]: attachmentsApi.reducer,
  [disbursementApi.reducerPath]: disbursementApi.reducer,
  [reportApi.reducerPath]: reportApi.reducer,
  [organizationsApi.reducerPath]: organizationsApi.reducer,
  [leadsApi.reducerPath]: leadsApi.reducer,
  [additionalContractApi.reducerPath]: additionalContractApi.reducer,
});

export default reducer;
